html {
  height: 100vh;
}

.App {
  text-align: center;
}

.full {
  height: 80vh;
}